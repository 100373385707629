import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { routes } from "../routes"

export default class extends Controller {
  static targets = ["element", "hint"]
  static values = { route: String }

  async connect() {
    await this.load()
  }

  async load() {
    const response = await get(
      routes[this.routeValue]({ key: this.elementTarget.value }),
      { responseKind: "json" }
    );

    if (!response.ok) { return }

    const data = await response.json
    this.hintTarget.textContent = data.text
  }
}
