import { masks } from "@uenf/layout/src/index";

masks.money = {
  mask: Number,
  scale: 2,
  signed: false,
  thousandsSeparator: ".",
  radix: ",",
  mapToRadix: ["."],
  padFractionalZeros: true,
  normalizeZeros: true,
  configure(input, imask) {
    input.form.addEventListener('submit', _event => {
      const number = imask.masked.number
      imask.destroy()
      input.value = number
    })
  }
};

masks["codigo-despesa"] = { mask: "0000.00.00" };

masks["codigo-classe"] = { mask: "0000" };

masks.referencia = { mask: "0000.000.0000" };
