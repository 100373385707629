import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["valorMaterial", "soma"]

  connect() {
    this.formatter = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
    this.change();
  }

  change(_event) {
    let sum;
    if (this.valorMaterialTargets) {
      sum = this.valorMaterialTargets
        // filter out destroyed items
        .filter(input => document.getElementById(input.id.replace("valor_total", "_destroy")).value != "1")
        .map(input => input.value)
        .filter(value => value && value.length > 0)
        .map(this.toFloat)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        .toFixed(2);
    } else if (this.hasValorMaterialTarget) {
      sum = this.toFloat(this.valorMaterialTarget.value)
    }
    this.somaTarget.innerText = this.formatter.format(sum || 0.0);
  }

  toFloat(value) {
    if (!value || value.length == 0) { return 0.0 }

    return parseFloat(value.replaceAll(".", "").replace(",", "."))
  }
}
