document.addEventListener('turbo:load', function() {
  const tipos_restritos = document.getElementsByClassName('solicitante_tipos_restritos')[0];
  const papel_select = document.getElementById('solicitante_papel_id');

  const ocultar_tipos_restritos = function() {
    tipos_restritos.style.visibility = 'hidden';
  };

  const mostrar_tipos_restritos = function() {
    tipos_restritos.style.visibility = 'visible';
  };

  const desmarcar_tipos_restritos = function() {
    const tipos = document.
      querySelectorAll('#solicitantes .check_boxes .form-check-input')
    for (let tipo of tipos) { tipo.checked = false; }
  };

  if (papel_select) {
    admin_option = papel_select.options[1];
    if (admin_option.selected == true) {
      ocultar_tipos_restritos();
    } else {
      papel_select.addEventListener('change', function(event) {
        const index_option = papel_select.selectedIndex;
        const option = papel_select.options[index_option];
        if (option.text == 'Administrador' && option.selected == true) {
          ocultar_tipos_restritos();
          desmarcar_tipos_restritos();
        } else {
          mostrar_tipos_restritos();
        }
      });
    }
  };
});
